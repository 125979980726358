
.text-website
{
  color: #00a19a !important;
}

.btn-info {
  background: #00a19a !important;
  color: white !important;
}


.bg-website {
  background: #00a19a !important;
  color: white !important;
}

a {
  color: #00a19a !important;
}
a:hover {
  color: #00a19a !important;
  font-weight: 600;
}

.footer, a:hover {
  color: #096479 !important;
  font-weight: 600;
}

.list-group-item.active
{
  background: #00a19a !important;
  color: white !important;
}

.slider {
  position: relative;
  z-index: 0;
}

.apponintment-form {
  display: flex;
  justify-content: center;
}

@media (max-width: 1650px) {
  .apponintment-form {
    width: 350px;
    position: absolute;
    top: 65px;
    right: 20px;
    z-index: 1;
    padding: 20px 0;
  }
}

@media (max-width: 1440px) {
  .apponintment-form {
    width: 350px;
    position: absolute;
    top: 65px;
    right: 20px;
    z-index: 1;
    padding: 20px 0;
  }
}

@media (max-width: 1025px) {
  .apponintment-form {
    width: 380px;
    position: absolute;
    top: 3px;
    right: 4px;
    z-index: 1;
  }
}

@media (max-width: 768px) {
  .apponintment-form {
    width: 270px;
    position: absolute;
    top: 3px;
    right: 4px;
    z-index: 1;
  }
}

@media (max-width: 426px) {
  .apponintment-form {
    width: 100%;
    position: unset;
    top: 0;
    right: 0;
    z-index: 1;
  }
}

@media (max-width: 376px) {
  .apponintment-form {
    width: 100%;
    position: unset;
    top: 0;
    right: 0;
    z-index: 1;
  }
}

@media (max-width: 321px) {
  .apponintment-form {
    width: 100%;
    position: unset;
    top: 0;
    right: 0;
    z-index: 1;
  }
}

.carousel-indicators {
  display: none !important;
}
.carousel-caption {
  position: absolute;
  right: 31% !important;
  bottom: 4.25rem !important;
  left: 5% !important;
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
  color: #fff;
  text-align: center;
}

.app-card-article {
  position: relative;
  width: 100%;
}

.articleContainer {
  background: #00a19a; 
  color: #fff; 
}

.form-control{
  appearance: auto !important;
}


.whatWeDo:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}
.doctor-card {
  transition: transform 0.3s ease; 
}

.doctor-card:hover {
  transform: scale(1.05); }

.input-group {
  position: relative;
}

.input-group .form-control {
  padding-right: 30px; 
}

.input-group .percentage {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #000; 
}

@keyframes blink {
  60% {
    opacity: .3;
  }
}

.blink {
  animation: blink 2s step-start infinite;
  font-weight: 800;
}